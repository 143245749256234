/*
 * @Description:
 * @Author: Jumbo
 * @Date: 2024-03-05 17:13:41
 * @LastEditors: Jumbo
 * @LastEditTime: 2024-03-12 14:52:23
 * @FilePath: index.ts
 */
import { type App } from 'vue'
import SvgIcon from '@/components/SvgIcon/index.vue' // Svg Component
import 'virtual:svg-icons-register'

export function loadSvg(app: App) {
  app.component('SvgIcon', SvgIcon)
}
