/*
 * @Description:
 * @Author: Jumbo
 * @Date: 2024-02-27 10:38:28
 * @LastEditors: Jumbo
 * @LastEditTime: 2024-03-05 17:14:15
 * @FilePath: main.ts
 */
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import 'element-plus/dist/index.css'
import '@/assets/styles/index.scss'

import { loadPlugins } from '@/plugins'
import { loadSvg } from '@/icons'

const app = createApp(App)

app.use(createPinia())
app.use(router)
/** 加载插件 */
loadPlugins(app)
loadSvg(app)

app.mount('#app')
