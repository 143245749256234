/*
 * @Description:
 * @Author: Jumbo
 * @Date: 2024-01-08 10:58:34
 * @LastEditors: Jumbo
 * @LastEditTime: 2024-01-12 16:22:53
 * @FilePath: index.ts
 */
import { type App } from 'vue'
import { loadElementPlus } from './element-plus'
import { loadElementPlusIcon } from './element-plus-icon'

export function loadPlugins(app: App) {
  loadElementPlus(app)
  loadElementPlusIcon(app)
}
