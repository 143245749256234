/*
 * @Description:
 * @Author: Jumbo
 * @Date: 2024-03-01 18:11:37
 * @LastEditors: Jumbo
 * @LastEditTime: 2024-03-25 17:18:36
 * @FilePath: useTitle.ts
 */
import { ref, watch } from 'vue'

/** 项目标题 */
const VITE_APP_TITLE = import.meta.env.VITE_APP_TITLE ?? '股小圈'

/** 动态标题 */
const dynamicTitle = ref<string>('')

/** 设置标题 */
const setTitle = (title?: string) => {
  dynamicTitle.value = title ? `${VITE_APP_TITLE} | ${title}` : VITE_APP_TITLE
}

/** 监听标题变化 */
watch(dynamicTitle, (value, oldValue) => {
  if (document && value !== oldValue) {
    document.title = value
  }
})

export function useTitle() {
  return { setTitle }
}
