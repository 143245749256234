/*
 * @Description:
 * @Author: Jumbo
 * @Date: 2024-02-27 10:38:28
 * @LastEditors: Jumbo
 * @LastEditTime: 2024-12-03 11:52:14
 * @FilePath: index.ts
 */
import { createRouter, createWebHistory } from 'vue-router'
import { useTitle } from '@/hooks/useTitle'
import { setRouteChange } from '@/hooks/useRouteListener'
const Layouts = () => import('@/layout/index.vue')
const { setTitle } = useTitle()
export const routes = [
  {
    path: '/',
    component: Layouts,
    name: 'main',
    redirect: '/predicts',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/index.vue'),
        name: 'Home',
        meta: {
          title: '首页',
          elIcon: 'HomeFilled'
        }
      },
      {
        path: '/predicts',
        component: () => import('@/views/predicts/index.vue'),
        name: 'Predict',
        meta: {
          title: '我的自选',
          elIcon: 'StarFilled',
          control: 'predicts',
          breadcrumb: true
        }
      },
      {
        path: '/stock/:code',
        component: () => import('@/views/predicts/stock.vue'),
        name: 'stock',
        meta: {
          title: '股票详情'
        }
      },
      {
        path: '/predict/:code',
        component: () => import('@/views/predicts/predict.vue'),
        name: 'predict',
        meta: {
          title: '自选股票'
        }
      },
      // {
      //   path: '/circles',
      //   component: () => import('@/views/predicts/index.vue'),
      //   name: 'Circles',
      //   meta: {
      //     title: '小圈子',
      //     elIcon: 'HelpFilled',
      //     control: 'circles'
      //   }
      // },
      // {
      //   name: 'Strategies',
      //   path: '/strategies',
      //   redirect: '/grids',
      //   children: [
      //     {
      //       path: '/grids',
      //       component: () => import('@/views/grids/index.vue'),
      //       name: 'grids',
      //       meta: {
      //         title: '网格交易',
      //         control: 'grids'
      //       }
      //     },
      //     {
      //       path: '/portfolios',
      //       component: () => import('@/views/portfolios/index.vue'),
      //       name: 'portfolios',
      //       meta: {
      //         title: '组合',
      //         control: 'portfolios'
      //       }
      //     }
      //   ],
      //   meta: {
      //     title: '策略',
      //     elIcon: 'Coin',
      //     control: 'strategies'
      //   }
      // },
      {
        path: '/versus',
        component: () => import('@/views/versus/index.vue'),
        name: 'Versus',
        meta: {
          title: '股票PK',
          elIcon: 'SetUp',
          control: 'versus'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      isPublic: true
    },
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/auth/login.vue')
  }
]
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export const buildSidebar = () => {
  const sidebars: any[] = []
  routes.forEach((item: any) => {
    if (item.name === 'main') {
      item.children.forEach((itm: any) => {
        if (itm.children && Array.isArray(itm.children)) {
          const childMenu = itm.children.filter((menu: any) => {
            return !!menu.meta.control
          })
          if (childMenu && childMenu.length > 0) {
            itm.children = childMenu
            sidebars.push(itm)
          }
        } else if (itm.meta.control) {
          sidebars.push(itm)
        }
      })
    }
  })
  return sidebars
}

router.afterEach((to) => {
  setRouteChange(to)
  setTitle(to.meta.title)
})

export default router
